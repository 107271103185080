<template>
  <div>
    <aw-section-wrapper :has-padding="false">
      <template #content>
        <client-only>
          <aw-tab-carousel
            :main-title="null"
            :empty-placeholder="$awt('aw.cms_carousel.fallback.text')"
            :tabs="tabs"
            :breakpoints="breakpoints"
            :slides="slides"
            tab-key="id"
            slide-key="id"
            :class="$style.tabCarousel"
          >
            <template #slide(all)(all)="{ slide }">
              <aw-cms-card
                :content="{...slide, backgroundColor: {hex: $styleVariables.colorBackground3, alpha: 1 }}"
                :analytics-banner-interaction="analyticsBannerInteraction"
                :analytics-measurable="analyticsMeasurable"
                :full-component-link="slide.link"
                bordered
              />
            </template>
          </aw-tab-carousel>
        </client-only>
      </template>
    </aw-section-wrapper>
  </div>
</template>

<script>
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';

  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'pinia';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  export default {
    name: 'AwFullCarouselCmsCard',
    components: {
      AwTabCarousel: defineAsyncComponent(() => import('~~/common/components/AwTabCarousel')),
      AwSectionWrapper: defineAsyncComponent(() => import('~~/common/components/Page/Common/AwSectionWrapper')),
      AwCmsCard: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCmsCard')),
    },
    mixins: [analyticsBannerInteractionMixin],
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    data () {
      const tabs = [
        {
          id: 'current',
          tabTitle: '',
        },
      ];
      const slides = {
        current: this.content.components,
      };
      return {
        selectedTabId: 'current',
        slides,
        tabs,
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      breakpoints () {
        return [
          {
            at: this.screenRange['desktop-large-min'],
            count: 5,
          },
          {
            at: this.screenRange['desktop-medium-min'],
            count: 4,
          },
          {
            at: this.screenRange['tablet-min'],
            count: 3,
          },
          {
            at: true,
            count: 'auto',
          },
        ];
      },
    },
  };
</script>


<style module lang="scss" rel="stylesheet/scss">

.tabCarousel {
  --awTabItemPadding: 14px 16px;
  --awTabCarouselParentPaddingTop: 0;

  @include tablet(min) {
    --awTabItemPadding: 12px 16px;
    --awTabCarouselMinHeight: 100%;
  }
}

</style>
